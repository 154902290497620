
import { defineComponent } from '@nuxtjs/composition-api'
import { VNode } from 'vue'
import { jsonToHtml } from '@contentstack/json-rte-serializer'

import VRuntimeTemplate from 'v-runtime-template'
import unescape from 'lodash/fp/unescape'
import Button from '../loading/Button.vue'
import JsonRteDocRoot from './JsonRteDocRoot.vue'
import Table from '~/components/feature/static-content/components/HTML-Table.vue'
import ContentVideo from '~/components/feature/static-content/components/ContentVideo.vue'
import ContentImage from '~/components/feature/static-content/components/ContentImage.vue'
import DirectImg from '~/components/feature/static-content/DirectImg.vue'
import ZoomImage from '~/components/feature/static-content/components/ZoomImage.vue'

// Common
import { CUSTOM_TEXTS, CUSTOM_BLOCKS, BLOCK_LEVEL_ELEMENTS } from '~/common/constants/rte'
import {
  TreeLeaf,
  TreeNode,
  EmbeddedItemImage,
  EmbeddedItemVideo,
  EmbeddedItemTable,
  EmbeddedItemFragment,
  EmbeddedItem,
  RteNode,
  ButtonWidths,
} from '~/types/rte/rteTypes'
import CmsAsset from '~/components/feature/static-content/components/CmsAsset.vue'

export default defineComponent({
  name: 'JsonRteFieldComponent',
  props: {
    node: {
      type: Object as () => RteNode,
      required: true,
    },
    embeddedItems: {
      type: Array as () => {} | null,
      required: false,
      default: () => {},
    },
  },

  render(h): VNode {
    const node = this.$props.node as RteNode
    const embeddedItems = this.$props.embeddedItems as EmbeddedItem[]

    const isTreeLeaf = (node: TreeNode | TreeLeaf) => Object.keys(node).includes('text')
    const isSimpleTextNode = (node: TreeNode | TreeLeaf) => Object.keys(node).length === 1

    const getEmbeddedItem = (uuid: string) => {
      return embeddedItems ? embeddedItems.find((item) => item.node.system.uid === uuid) : null
    }

    // .. This adapter applies "default" text wrapper extensions
    const jsonToHtmlAdapter = (...args: Parameters<typeof jsonToHtml>) =>
      jsonToHtml(args[0], {
        allowNonStandardTypes: true,
        //
        customTextWrapper: {
          [CUSTOM_TEXTS.ITALIC]: (child) => {
            return `<i>${child}</i>`
          },
          [CUSTOM_TEXTS.CITE]: (child) => {
            return `<cite>${child}</cite>`
          },
          [CUSTOM_TEXTS.ABBR]: (child, value) => {
            return `<abbr title="${value}">${child}</abbr>`
          },
          [CUSTOM_TEXTS.CUSTOM_RED_TEXT]: (child, _) => {
            return `<span class="tw-text-monza-500">${child}</span>`
          },
          [CUSTOM_TEXTS.CUSTOM_HIGHLIGHT_TEXT]: (child, tailwindColorClass) => {
            return `<span class="${tailwindColorClass}">${child}</span>`
          },
          ...args[1]?.customTextWrapper,
        },
      })

    const renderChildren = (children: Array<TreeNode | TreeLeaf>) => {
      /**
       * NUXT-2 can return specifically 1 root element:
       * - It cannot return null, or empty component
       * - It cannot return more then one root node
       *
       * Because of that, any normalization has to be done on this level. This
       * way we can remove unwanted elements, or prevent some rendering.
       */

      const normalizedChildren = children.reduce((arr, child) => {
        const prevChild = arr.at(-1)

        /**
         * To prevent hydration errors, we want to skip empty simple tree leafs
         * Those are simple {text:''} without any marks, and purpose what so ever
         */
        if (isTreeLeaf(child) && isSimpleTextNode(child) && (child as TreeLeaf).text.length === 0) {
          return arr
        }

        /**
         * Some text chunks happen to be splitted into multiple TreeLeafs
         * even if they do not need to.
         *
         * That is causing issues with breaking the hydration, and it is not
         * matching old ATG structure.
         *
         * Because of that we merge `simpleTextNodes` (the ones containing only "text"
         * without any marks), to be in big chunks => so we reduce number of elements
         */

        if (
          prevChild &&
          isTreeLeaf(prevChild) &&
          isSimpleTextNode(prevChild) &&
          isTreeLeaf(child) &&
          isSimpleTextNode(child)
        ) {
          const childAsLeaf = child as TreeLeaf
          const prevChildAsLeaf = prevChild as TreeLeaf

          const mergedTextLeaf = { ...childAsLeaf, text: prevChildAsLeaf.text + childAsLeaf.text }
          arr[arr.indexOf(prevChild)] = mergedTextLeaf
          return arr
        }

        /**
         * We also need to reduce and merge 'fragment' elements that contain only 'text' in child
         * to avoid hydration issues caused by the absence of a single root node.
         */

        if (
          prevChild &&
          prevChild.type === 'fragment' &&
          prevChild.children.length === 1 &&
          isTreeLeaf(prevChild.children[0]) &&
          isSimpleTextNode(prevChild.children[0]) &&
          child.type === 'fragment' &&
          child.children.length === 1 &&
          isTreeLeaf(child.children[0]) &&
          isSimpleTextNode(child.children[0])
        ) {
          const childAsLeaf = child.children[0] as TreeLeaf
          const prevChildAsLeaf = prevChild.children[0] as TreeLeaf

          const mergedTextLeaf = { ...childAsLeaf, text: prevChildAsLeaf.text + childAsLeaf.text }
          arr[arr.indexOf(prevChild)] = mergedTextLeaf
          return arr
        }

        /**
         * Make number of <br> elements to match number of '\n\n' in text. So it
         * looks the same way like in cms
         */

        if (isTreeLeaf(child) && (child as TreeLeaf).break) {
          const howManyBreaks = [...(child as TreeLeaf).text.matchAll(/\n/g)]

          howManyBreaks.forEach(() => {
            arr.push({ ...child, text: '\n' })
          })
          return arr
        }

        /**
         * Here you can extend ...
         */

        // .. By default we just pass a children
        arr.push(child)

        return arr
      }, [] as Array<TreeNode | TreeLeaf>)

      // ..
      return normalizedChildren.map((childNode: RteNode) =>
        // .. Here is important to pass all props for recursive rendering
        h('JsonRteFieldComponent', { props: { node: childNode, embeddedItems } })
      )
    }
    // .. Here the leaf is being rendered
    if (!node.type) {
      const leafNode = node as TreeLeaf

      /**
       * Some Leaf nodes contain a mark called "break",
       * in that case we need to render <br />
       *
       * Because it is self-closing tag, we cannot use VRuntimeTemplate
       */
      if (leafNode?.break) {
        return h('br')
      }

      // if leaf contains any marks (CUSTOM_TEXTS), `jsonToHtmlAdapter` will wrap text in html <span> wrapper
      const htmlOrText = jsonToHtmlAdapter(leafNode)
      const htmlContainsWrapper = htmlOrText.includes('</') // includes end of wrapping element

      /**
       * <span>...</span> is a valid single root node element which can be rendered
       * using of VRuntimeTemplate. It can also contain nested structure, which will
       * be automatically rendered using VRuntimeTemplate
       */
      if (htmlContainsWrapper) {
        return h(VRuntimeTemplate, { props: { template: htmlOrText } })
      }

      /**
       * To render and display text node, we need to create empty node, which is by default
       * treated as a "comment" in DOM.
       *
       * By adding text and saying it is not an comment we can create a valid single
       * root element node, which does not include any tag, its just text
       */

      const textNode = h()

      // We shouldn't receive an empty text here, it should be handled in normalizeChildren
      textNode.text = unescape(htmlOrText)
      textNode.isComment = false
      textNode.isRootInsert = false

      return textNode
    }

    // .. Here extend based on custom elements
    switch (node?.type) {
      // .. "doc" is root element, we can apply custom styling within this component
      case 'doc':
        return h(JsonRteDocRoot, renderChildren(node.children))

      // .. Custom Blocks (Plugins)
      case CUSTOM_BLOCKS.BUTTON: {
        const href = node.attrs.href
        const variant = node.attrs.variant
        const placement: 'start' | 'center' | 'end' = node.attrs.placement
        const width: ButtonWidths = node.attrs.width

        return h(
          'div',
          {
            attrs: { 'data-test-id': 'JsonRteField-ButtonWrapper' },
            class: `tw-flex tw-w-full`,
            style: { justifyContent: placement },
          },
          [h(Button, { props: { href, width }, class: `${variant} ` }, renderChildren(node.children))]
        )
      }

      case CUSTOM_BLOCKS.LAYOUT: {
        return h(
          'div',
          {
            attrs: { 'data-test-id': 'JsonRteField-CstLayout-wrapper' },
            class: 'tw-flex tw-w-full tw-flex-col md:tw-flex-row',
          },
          [
            node.children.map((child: RteNode) => {
              // .. CstLayout always contain 2 DIVs with sizes
              let width = 'tw-w-[100%] '
              switch (child.attrs.width) {
                case 33:
                  width += 'md:tw-w-1/3'
                  break
                case 50:
                  width += 'md:tw-w-1/2'
                  break
                case 66:
                  width += 'md:tw-w-2/3'
                  break
                default:
                  width += 'md:tw-w-full'
              }

              return h(
                child.type,
                { class: width, attrs: { name: CUSTOM_BLOCKS.LAYOUT } },
                renderChildren(child.children)
              )
            }),
          ]
        )
      }

      case CUSTOM_BLOCKS.ZOOM_MODAL: {
        const variant = node.attrs?.variant ?? 'fifty-fifty'
        const modalTitle = node.attrs?.modalTitle ?? ''
        const showCaptionInModal = !!node.attrs?.showCaptionInModal

        return h(ZoomImage, { props: { variant, modalTitle, showCaptionInModal } }, [
          h('JsonRteFieldComponent', { slot: 'contentImage', props: { node: node.children[0], embeddedItems } }),
          h('JsonRteFieldComponent', { slot: 'caption', props: { node: node.children[1], embeddedItems } }),
        ])
      }

      case CUSTOM_BLOCKS.ALPHA_OL: {
        return h(
          'ol',
          { attrs: { ...node.attrs, type: 'a' }, style: node.attrs.style ?? {} },
          renderChildren(node.children)
        )
      }

      case CUSTOM_BLOCKS.LIST_ITEM: {
        return h('li', { attrs: node.attrs, style: node.attrs.style ?? {} }, renderChildren(node.children))
      }

      case CUSTOM_BLOCKS.REFERENCE: {
        const contentTypeUid = node.attrs['content-type-uid'] as string | undefined
        const referenceEntryUid = node.attrs['entry-uid']
        const embeddedReference = getEmbeddedItem(referenceEntryUid)
        const className = node.attrs['class-name'] ?? ''
        const displayType = node.attrs['display-type'] ?? 'block'

        // .. Content image reference
        if (contentTypeUid && embeddedReference && contentTypeUid.includes('image')) {
          const imageDetails = embeddedReference.node as EmbeddedItemImage

          return h(ContentImage, {
            class: className,
            props: {
              data: {
                media_server_id: imageDetails.media_server_id ?? null,
                cloudflare_id: imageDetails.cloudflare_id ?? null,
                alt_text: imageDetails.alt_text ?? null,
                caption: imageDetails.caption,
              },
            },
          })
        }

        // content video reference
        if (contentTypeUid && embeddedReference && contentTypeUid.includes('video')) {
          const videoDetail = embeddedReference.node as EmbeddedItemVideo

          return h(ContentVideo, {
            class: className,
            props: {
              data: {
                video_id: videoDetail.video_id,
                collection: videoDetail.collection,
                video_provider: videoDetail.video_provider,
                isPopover: true,
              },
            },
            style: {
              display: displayType,
            },
          })
        }

        // content table reference
        if (contentTypeUid && embeddedReference && contentTypeUid.includes('table')) {
          const tableDetail = embeddedReference.node as EmbeddedItemTable
          return h(Table, {
            props: {
              data: {
                html_table: tableDetail.html_table,
                heading: tableDetail.heading,
              },
            },
            attrs: { name: 'embeddedRteTable' },
          })
        }

        // content fragment reference
        if (contentTypeUid && embeddedReference && contentTypeUid.includes('fragment')) {
          const fragmentDetail = embeddedReference.node as EmbeddedItemFragment
          if (fragmentDetail.content?.json?.children.length > 1) {
            return h('div', renderChildren(fragmentDetail.content.json.children))
          } else {
            return renderChildren(fragmentDetail.content.json.children) as unknown as VNode
          }
        }

        // .. CMS Asset ('sys-asset')
        if (contentTypeUid && contentTypeUid.includes('sys_assets')) {
          return h(CmsAsset, {
            props: { node },
          })
        }

        // Return Empty div as fallback
        return h('div', { attrs: { 'data-fallback': `Couldn't find correct contentTypeUid` } })
      }

      // non embedded img
      case 'img': {
        return h(DirectImg, { props: { attrs: node.attrs } })
      }

      // .. Skipping rendering for following cases:
      case 'fragment': // wrapped around ol-s, not sure why it is so
        // .. There should always be only one root node
        if (node.children.length > 1) {
          return h('div', renderChildren(node.children))
        } else {
          return renderChildren(node.children) as unknown as VNode
        }

      // anchor link puts 'url' instead of href
      case 'a': {
        const href = node.attrs.url ?? ''
        return h('a', { attrs: { href, ...node.attrs }, style: node.attrs.style ?? {} }, renderChildren(node.children))
      }

      // if you embed inline reference in "p" it will cause hydration error
      case 'p': {
        const areAnyChildrenBlockElements = node.children?.some(
          (node: TreeNode) => node?.type && BLOCK_LEVEL_ELEMENTS.includes(node.type)
        )

        // Swapping "p" for "div" to prevent mismatch nodes
        if (areAnyChildrenBlockElements) {
          return h('div', { attrs: node.attrs, style: node.attrs.style ?? {} }, renderChildren(node.children))
        }

        return h('p', { attrs: node.attrs, style: node.attrs.style ?? {} }, renderChildren(node.children))
      }

      // .. Default rendering element type in node
      default: {
        return h(node.type, { attrs: node.attrs, style: node.attrs.style ?? {} }, renderChildren(node.children))
      }
    }
  },
})
