import { EvaluateOption } from '~/types/featureFlags/featureFlagsPlugin'

export const FEATURES = defineFeatures({
  search_use_productnumber_precision: {
    name: 'search_use_productnumber_precision',
    evaluateBasedOn: 'email',
    type: 'boolean',
    cnFlag: false,
  },
  showNewCitations: {
    name: 'show_new_citation_brick',
    evaluateBasedOn: 'email',
    type: 'boolean',
    cnFlag: process.env.CN_SHOW_NEW_CITATIONS === 'true',
  },
  paneBuilderIdSubmit: {
    name: 'signalstar_panelBuilderIdSubmit_boolean',
    evaluateBasedOn: 'email',
    type: 'boolean',
    cnFlag: false,
  },
  showGop: {
    name: 'gop_enabled_boolean',
    evaluateBasedOn: 'country',
    type: 'boolean',
    cnFlag: false,
  },
  newSearchUrlStructure: {
    name: 'use_new_search_url_structure',
    evaluateBasedOn: 'country',
    type: 'boolean',
    cnFlag: false,
  },
  enableMultiEuVat: {
    name: 'enable_multi_eu_vat',
    evaluateBasedOn: 'email',
    type: 'boolean',
    cnFlag: false,
  },
  showCrossSelling: {
    name: 'show_cross_selling',
    evaluateBasedOn: 'eventSessionId',
    type: 'boolean',
    cnFlag: false,
  },
  showIntraseqCrossSelling: {
    name: 'show_intraseq_cross_selling',
    evaluateBasedOn: 'email',
    type: 'boolean',
    cnFlag: false,
  },
})

export type FeatureDefinitions = typeof FEATURES

type FeatureFlagOptions<TName extends string> = {
  name: TName
  evaluateBasedOn: EvaluateOption
  type: 'boolean' | 'string' | 'number'
  cnFlag: boolean | string
}

// This function is required, because typescript 4.2 doesn't support satisfies keyword
// (`as const` cannot be used because it sets all properties to readonly)
function defineFeatures<T extends Record<string, FeatureFlagOptions<string>>>(options: T): T {
  return options
}

type StringToType<T extends string> = T extends 'boolean'
  ? boolean
  : T extends 'string'
  ? string
  : T extends 'number'
  ? number
  : never

export type EvaluateFeature<T extends FeatureDefinitions[keyof FeatureDefinitions]> = {
  value: StringToType<T['type']>
  name: T['name']
  evaluateBasedOn: T['evaluateBasedOn']
}

export type EvaluatedFeatures = {
  [K in keyof FeatureDefinitions]: EvaluateFeature<FeatureDefinitions[K]>
}
