// Pinia
import { defineStore } from 'pinia'
import { useRoute } from '@nuxtjs/composition-api'
// Store
import { useUserStore } from './user'
// Types
import {
  ProductSKUInterface,
  ProductDataInterface,
  MultipleProductDataInterface,
  ProductPriceInterface,
  MappedProduct,
  MappedSKUAttributes,
  MappedSKU,
  MappedProductAttributes,
  Application,
  Lot,
  ProductInterface,
  Figure,
  Conjugation,
  StepProtocol,
  ReferencedProduct,
  ProductBrief,
  CitationsResultsResponse,
  CitationsImagesResponse,
} from '@/types/product/productTypes'
import { SupportingDataAttribute } from '~/types/supportingData'
import { ProductIncludesAttribute } from '~/types/productIncludesData'
import { ComponentName } from '~/types/productInformation'

import {
  APPLICATIONS,
  PRODUCT_INFORMATION_COMPONENTS,
  applicationsWithOwnChiclet,
} from '~/common/utils/product/constants'
import {
  mapSupportingDataAttributeToLabel,
  mapSupportingDataAttributeToValue,
  mapProductIncludesAttributeToLabel,
  mapProductIncludesAttributeToValue,
  mapChicletsForApplicationTab,
  filterSkuWithNonZeroPrice,
  getDilutionsFromApplications,
  getEclipMessageFromApplications,
  getAllSpecies,
  extractProductIdFromSku,
} from '~/common/utils/product/product'

import { useCountry } from '~/composables/country/useCountry'
import { usePriceBrick } from '~/composables/price-brick/usePriceBrick'

export const useProductStore = defineStore({
  id: 'product',
  state: (): MultipleProductDataInterface => ({
    productsData: [],
    isLoading: false,
    products: {},
    citationData: {},
  }),
  actions: {
    async fetchFullProduct(productId: string) {
      if (!this.shouldCallApi(productId)) {
        return this.products[productId]
      } else {
        this.isLoading = true
        try {
          let productData
          const { data } = !this.$nuxt.$config.cst.isPreview
            ? await this.$nuxt.$ct.products.getFullProduct(productId)
            : await this.$nuxt.$cstApi.getPreviewFullProduct(productId)
          if (!this.$nuxt.$config.cst.isPreview) {
            try {
              productData = JSON.parse(data.value.cache)
            } catch (e) {
              console.error('Product cached data parsing error: ' + e)
            }
          } else {
            productData = data
          }

          this.patchProductStore(productData)
          return this.products[productId]
        } finally {
          this.isLoading = false
        }
      }
    },
    async fetchProductCitationData(
      productId: string,
      params?: { limit?: number; offset?: number; application?: string; reactants?: string }
    ) {
      const url = new URL(`${this.$nuxt.$config.cst.citationsUrl}/productCitations/${productId}`)
      url.searchParams.append('limit', (params?.limit ?? 100).toString())
      url.searchParams.append('offset', (params?.offset ?? 0).toString())

      if (params?.application) {
        url.searchParams.append('application', params.application)
      }
      if (params?.reactants) {
        url.searchParams.append('reactants', params.reactants)
      }

      const { data } = await this.$nuxt.$axios.get<CitationsResultsResponse>(url.toString())
      return data
    },
    async fetchProductCitationImagesData(productId: string, params?: { application?: string; reactants?: string }) {
      const url = new URL(`${this.$nuxt.$config.cst.citationsUrl}/productImages`)
      url.searchParams.append('productid', productId)

      if (params?.application) {
        url.searchParams.append('application', params.application)
      }
      if (params?.reactants) {
        url.searchParams.append('reactants', params.reactants)
      }

      const { data } = await this.$nuxt.$axios.get<CitationsImagesResponse>(url.toString())

      return data
    },
    async fetchProductCitationCount(productId: string) {
      const { data } = await this.$nuxt.$axios.get<{
        citations_count: number
      }>(`${this.$nuxt.$config.cst.citationsUrl}/productCitations/${productId}/count`)

      return data.citations_count
    },
    patchProductStore(data: any, isReferencedProduct: boolean = false) {
      // delete the oldest product when there are already 75 products in the store
      if (Object.keys(this.products).length === 75) {
        delete this.products[
          Object.values(this.products).reduce((prev, curr) => {
            return curr.addedToTheStoreAt < prev.addedToTheStoreAt ? curr : prev
          }).data.key
        ]
      }
      if (!this.products[data.key]) {
        this.$patch({ products: Object.assign(this.products, mapProduct(data, isReferencedProduct)) })
      }
    },

    shouldCallApi(productId: string) {
      if (this.products[productId]) {
        return this.products[productId].isReferencedProduct
      } else return true
    },

    // FIXME: This is left here for backward compatibility
    mapProductData(data: any) {
      this.productsData = []

      for (let i = 0; i < data.results.length; i++) {
        const productData = {} as ProductDataInterface
        const masterVariantData = { price: [] as ProductPriceInterface[] } as ProductSKUInterface
        productData.skuData = []

        productData.key = data.results[i].key
        productData.lastModifiedAt = data.results[i].lastModifiedAt
        productData.createdAt = data.results[i].createdAt
        productData.id = data.results[i].id
        productData.version = data.results[i].version
        productData.hasStagedChanges = data.results[i].hasStagedChanges
        productData.published = data.results[i].published

        // TODO: Get Category Data

        productData.name = data.results[i].name['en-US']
        for (let j = 0; j < data.results[i].masterVariant.attributes.length; j++) {
          if (data.results[i].masterVariant.attributes[j].name === 'active') {
            productData.active = data.results[i].masterVariant.attributes[j].value
          }

          if (data.results[i].masterVariant.attributes[j].name === 'assemblyOptionCode') {
            productData.assemblyOptionCode = data.results[i].masterVariant.attributes[j].value
          }

          if (data.results[i].masterVariant.attributes[j].name === 'carrierFree') {
            productData.carrierFree = data.results[i].masterVariant.attributes[j].value
          }

          if (data.results[i].masterVariant.attributes[j].name === 'casNumber') {
            productData.casNumber = data.results[i].masterVariant.attributes[j].value
          }

          if (data.results[i].masterVariant.attributes[j].name === 'certificateOfAnalysis') {
            productData.certificateOfAnalysis = data.results[i].masterVariant.attributes[j].value
          }

          if (data.results[i].masterVariant.attributes[j].name === 'concentration') {
            productData.concentration = data.results[i].masterVariant.attributes[j].value
          }

          if (data.results[i].masterVariant.attributes[j].name === 'conjugated') {
            productData.conjugated = data.results[i].masterVariant.attributes[j].value
          }

          if (data.results[i].masterVariant.attributes[j].name === 'epitopeTag') {
            productData.epitopeTag = data.results[i].masterVariant.attributes[j].value
          }

          if (data.results[i].masterVariant.attributes[j].name === 'groupName') {
            productData.groupName = data.results[i].masterVariant.attributes[j].value
          }

          if (data.results[i].masterVariant.attributes[j].name === 'host') {
            productData.host = data.results[i].masterVariant.attributes[j].value
          }

          if (data.results[i].masterVariant.attributes[j].name === 'isotype') {
            productData.isotype = data.results[i].masterVariant.attributes[j].value
          }

          if (data.results[i].masterVariant.attributes[j].name === 'lot') {
            productData.lot = data.results[i].masterVariant.attributes[j].value
          }

          if (data.results[i].masterVariant.attributes[j].name === 'observedMolecularWt') {
            productData.observedMolecularWeight = data.results[i].masterVariant.attributes[j].value
          }

          if (data.results[i].masterVariant.attributes[j].name === 'productStorage') {
            productData.productStorage = data.results[i].masterVariant.attributes[j].value
          }

          if (data.results[i].masterVariant.attributes[j].name === 'productStorageTemperature') {
            productData.productStorageTemperature = data.results[i].masterVariant.attributes[j].value
          }

          if (data.results[i].masterVariant.attributes[j].name === 'productType') {
            productData.productType = data.results[i].masterVariant.attributes[j].value.label
          }

          if (data.results[i].masterVariant.attributes[j].name === 'releasedDate') {
            productData.releasedDate = data.results[i].masterVariant.attributes[j].value
          }

          if (data.results[i].masterVariant.attributes[j].name === 'safetyDatasheets') {
            productData.safetyDatasheets = data.results[i].masterVariant.attributes[j].value
          }

          if (data.results[i].masterVariant.attributes[j].name === 'sortName') {
            productData.sortName = data.results[i].masterVariant.attributes[j].value
          }

          if (data.results[i].masterVariant.attributes[j].name === 'sourcePurification') {
            productData.sourcePurification = data.results[i].masterVariant.attributes[j].value
          }

          if (data.results[i].masterVariant.attributes[j].name === 'specificitySensitivity') {
            productData.specificitySensitivity = data.results[i].masterVariant.attributes[j].value
          }

          if (data.results[i].masterVariant.attributes[j].name === 'statements') {
            productData.statements = data.results[i].masterVariant.attributes[j].value
          }

          if (data.results[i].masterVariant.attributes[j].name === 'status') {
            productData.status = data.results[i].masterVariant.attributes[j].value.label
          }

          if (data.results[i].masterVariant.attributes[j].name === 'targets') {
            productData.targets = data.results[i].masterVariant.attributes[j].value
          }

          if (data.results[i].masterVariant.attributes[j].name === 'xp') {
            productData.xp = data.results[i].masterVariant.attributes[j].value
          }
        }

        // master variant data
        masterVariantData.sku = data.results[i].masterVariant.sku

        for (let j = 0; j < data.results[i].masterVariant.attributes.length; j++) {
          if (data.results[i].masterVariant.attributes[j].name === 'amount') {
            masterVariantData.amount = data.results[i].masterVariant.attributes[j].value
          }
          if (data.results[i].masterVariant.attributes[j].name === 'uom') {
            masterVariantData.uom = data.results[i].masterVariant.attributes[j].value
          }
        }

        if (data.results[i].masterVariant.prices) {
          const price = data.results[i].masterVariant.prices.find((price: any) =>
            useCountry().isB2DCountry.value ? price.country === 'US' : price.country === useUserStore().selectedCountry
          )
          if (price) {
            masterVariantData.price.push({
              country: price.country,
              centAmount: price.value.centAmount,
              currencyCode: price.value.currencyCode,
              fractionDigits: price.value.fractionDigits,
            })
          }
        }

        productData.skuData.push(masterVariantData)

        // variants data
        for (let j = 0; j < data.results[i].variants.length; j++) {
          const variantsData = { price: [] as ProductPriceInterface[] } as ProductSKUInterface
          variantsData.sku = data.results[i].variants[j].sku

          // console.log('DEBUG: processing sku variant ' + variantsData.sku)

          for (let k = 0; k < data.results[i].variants[j].attributes.length; k++) {
            if (data.results[i].variants[j].attributes[k].name === 'amount') {
              variantsData.amount = data.results[i].variants[j].attributes[k].value
            }
            if (data.results[i].variants[j].attributes[k].name === 'uom') {
              variantsData.uom = data.results[i].variants[j].attributes[k].value
            }
            if (data.results[i].variants[j].attributes[k].name === 'code') {
              variantsData.code = data.results[i].variants[j].attributes[k].value
            }
            if (data.results[i].variants[j].attributes[k].name === 'active') {
              variantsData.active = data.results[i].variants[j].attributes[k].value
            }
            if (data.results[i].variants[j].attributes[k].name === 'published') {
              variantsData.published = data.results[i].variants[j].attributes[k].value
            }
          }

          if (data.results[i].variants[j].prices.length) {
            const price = data.results[i].variants[j].prices.find((price: any) =>
              useCountry().isB2DCountry.value
                ? price.country === 'US'
                : price.country === useUserStore().selectedCountry
            )
            if (price) {
              variantsData.price.push({
                country: price.country,
                centAmount: price.value.centAmount,
                currencyCode: price.value.currencyCode,
                fractionDigits: price.value.fractionDigits,
              })
            }
          }
          // Add this variant/sku to list of skus:
          productData.skuData.push(variantsData)
        } // end of variants data

        this.productsData.push(productData)
      }
    },
    /**
     * This function is used to fetch products and map them into smaller object.
     * It is being used for instance on QuickAdd and MultiQuickAdd. Fell free to expand returned obj.
     *
     * @param productSKU Product SKUs (4060S, ...)
     * @returns type ProductBrief[]: Brief information about products
     */
    async getProductBrief(productSKU: Array<string>) {
      this.isLoading = true
      try {
        const uniqueSKUs = [...new Set(productSKU)]
        const uniqueProductKey = uniqueSKUs.map((sku) => extractProductIdFromSku(sku))

        await Promise.allSettled(
          uniqueProductKey.map((productId) => {
            if (productId) {
              return this.fetchFullProduct(productId)
            }
            return null
          })
        )

        const briefProducts: ProductBrief[] = uniqueSKUs.map((sku) => {
          const productKey = extractProductIdFromSku(sku)
          const product = productKey && this.products?.[productKey]
          const skuFound = product && Object.keys(product.data.skuData).find((productSKU) => productSKU === sku)

          // .. Unable to fetch product
          if (!product || !skuFound) {
            return {
              sku,
              status: 'not found',
              productType: '',
              category: '',
            } as ProductBrief
          }

          // .. Build brief product
          return {
            sku,
            price: product.data.skuData[sku].priceForCurrentCountry,
            status: product.data.attributes.statusDates.status,
            productType: product.data.productType,
            category: product.data.productCategory ?? '',
          }
        })

        return briefProducts
      } finally {
        this.isLoading = false
      }
    },
  },
  getters: {
    getMultipleProducts: (state) => {
      return (productIds: string[]): Array<ProductInterface | undefined> =>
        productIds.map((productId) => state.products[productId])
    },
    getConjugateProductData: (state) => {
      return (productIds: string[]) =>
        productIds.reduce((records: Record<string, Conjugation>, currId) => {
          const product = state.products[currId]
          if (product && product.data.attributes.conjugation) {
            return { ...records, [currId]: product.data.attributes.conjugation }
          }

          return records
        }, {})
    },
    getStatements: (state) => {
      return (productId: string, statementName: string) => {
        const statementsArray: string[] = []
        state.products[productId]?.data.attributes.statements?.statement.map((statement) => {
          if (statement.type?.toLowerCase() === statementName) {
            statementsArray.push(statement.text)
          }
          return statementsArray
        })
        return statementsArray
      }
    },
    getSupportingData: (state) => {
      return (productId: string, attributes: SupportingDataAttribute[]) => {
        return attributes.map((attribute: SupportingDataAttribute) => ({
          label: mapSupportingDataAttributeToLabel(attribute),
          value: mapSupportingDataAttributeToValue(state.products[productId]?.data, attribute),
        }))
      }
    },
    getProductIncludesData: (state) => {
      return (productId: string, attributes: ProductIncludesAttribute[]) => {
        return {
          label: mapProductIncludesAttributeToLabel(attributes),
          value: mapProductIncludesAttributeToValue(
            state.products[productId]?.data.attributes?.kits?.component,
            attributes
          ),
        }
      }
    },
    getProductInformationPropData: (state) => {
      return (productId: string, componentsName: ComponentName[]) => {
        return componentsName.map((component: ComponentName) =>
          mapProductInformationComponents(component, state.products[productId]?.data)
        )
      }
    },
    getChicletsForApplicationTab: (state) => {
      return (productId: string) => {
        return mapChicletsForApplicationTab(state.products[productId]?.data)
      }
    },
    // NOTE: When the component has useProductStore, you always need to mock useRoute in tests because of this getter (see ToPurchasePdpWrapper/QuickAddTest tests)
    currentProduct(state): ProductInterface {
      // this is done this way, because very rarely it's being called outside of setup function and it fails on useRoute, but you're unable to mock $nuxt in tests, so this is a workaround
      try {
        const route = useRoute()
        const productId = route.value.params.id
        return state.products[productId]
      } catch (error) {
        console.warn('currentProduct getter - ', error)
        const route = this.$nuxt.route
        const productId = route.params.id
        return this.products[productId]
      }
    },
    hasCurrentProductAnyPriceToDisplay() {
      // Need to check for showPrices because we need to filter skus with zero price only for countries that are showing price
      if (usePriceBrick(this.$nuxt).showPrices()) {
        const skus = filterSkuWithNonZeroPrice(Object.values(this.currentProduct?.data?.skuData ?? {}))
        return skus.length > 0
      }
      return true
    },
  },
})

function mapProductInformationComponents(component: ComponentName, currentProduct: MappedProduct) {
  switch (component) {
    case PRODUCT_INFORMATION_COMPONENTS.REAGENTS_NOT_INCLUDED: {
      return {
        name: component,
        prop: { reagentsNotIncluded: currentProduct?.attributes.reagentsNotIncluded },
      }
    }
    case PRODUCT_INFORMATION_COMPONENTS.KIT_USAGE_INFORMATION: {
      return {
        name: component,
        prop: {
          kitUsageInformation: currentProduct?.attributes.kits?.component,
          protocols: currentProduct?.attributes.protocols,
        },
      }
    }
    case PRODUCT_INFORMATION_COMPONENTS.DESCRIPTION: {
      return {
        name: component,
        prop: {
          description: currentProduct?.description,
          productType: currentProduct?.productType,
          attributes: currentProduct?.attributes,
        },
      }
    }
    case PRODUCT_INFORMATION_COMPONENTS.USAGE_INFORMATION: {
      return {
        name: component,
        prop: {
          dilutions: getDilutionsFromApplications(currentProduct?.attributes.lots?.lot.applications?.application),
          directionsForUse: currentProduct?.attributes.directionsForUse,
          solutionsReagents: currentProduct?.attributes.solutionsReagents,
          formulation: currentProduct?.attributes.formulation,
          productStorage: currentProduct?.attributes.productStorage,
          eclipMessage: getEclipMessageFromApplications(currentProduct?.attributes.lots?.lot.applications?.application),
        },
      }
    }
    case PRODUCT_INFORMATION_COMPONENTS.PROTOCOL: {
      return {
        name: component,
        prop: {
          productName: currentProduct?.name,
          applications: currentProduct?.attributes.lots?.lot.applications?.application,
          protocols: currentProduct?.attributes.protocols,
        },
      }
    }
    case PRODUCT_INFORMATION_COMPONENTS.QUALITY_CONTROL: {
      return {
        name: component,
        prop: { qualityControl: currentProduct?.attributes.qualityControl },
      }
    }
    case PRODUCT_INFORMATION_COMPONENTS.SPECIFICITY_OR_SENSITIVITY: {
      return {
        name: component,
        prop: {
          specificitySensitivity: currentProduct?.attributes.specificitySensitivity,
          reacts: getAllSpecies(currentProduct?.attributes.lots?.lot.reactivity?.reacts),
          homologous: getAllSpecies(currentProduct?.attributes.homology?.homologous),
        },
      }
    }
    case PRODUCT_INFORMATION_COMPONENTS.SOURCE_OR_PURIFICATION: {
      return {
        name: component,
        prop: { sourcePurification: currentProduct?.attributes.sourcePurification },
      }
    }
    case PRODUCT_INFORMATION_COMPONENTS.BACKGROUND: {
      return {
        name: component,
        prop: {
          productBackground: currentProduct?.attributes.productBackground,
          references: currentProduct?.references,
        },
      }
    }
    case PRODUCT_INFORMATION_COMPONENTS.UPSTREAM_OR_DOWNSTREAM_PATHWAY_IMAGE: {
      return {
        name: component,
        prop: {
          upstreamDownstreamPathwayImage: currentProduct?.targets,
        },
      }
    }
    case PRODUCT_INFORMATION_COMPONENTS.DATABASE_LINKS: {
      return {
        name: component,
        prop: {
          entrezList: currentProduct.attributes.targetEntrezList,
          uniprotList: currentProduct.attributes.targetUniprotList,
        },
      }
    }
    case PRODUCT_INFORMATION_COMPONENTS.FLUORESCENT_PROPERTIES: {
      return {
        name: component,
        prop: { fluorescentProperties: currentProduct?.attributes.fluorescents?.fluorescent },
      }
    }
    default: {
      console.warn(`${component} did not match any of the specified cases.`)
      return {
        name: undefined,
        prop: {},
      }
    }
  }
}

function mapProduct(result: any, isReferencedProduct: boolean) {
  return {
    [result.key]: {
      addedToTheStoreAt: Date.now(),
      isReferencedProduct,
      data: mapProductData(result),
    },
  }
}

function mapProductData(result: any): MappedProduct {
  return {
    id: result.id,
    createdAt: result.createdAt,
    lastModifiedAt: result.lastModifiedAt,
    published: result.published,
    version: result.version,

    name: result.name?.['en-US'],
    productType: result.attributes.categories['product-type'],
    productCategory: result.attributes.categories['web-category-name'],
    sales: result.attributes.categories.sales,
    hasStagedChanges: result.hasStagedChanges,
    key: result.key,
    priceMode: result.priceMode,
    description: result.description?.['en-US'],

    skuData: mapProductSkuData(result),

    attributes: mapProductAttributes(result.attributes, result.name?.['en-US']),

    alternateProducts: mapReferencedProducts(mapEmptyArrayToUndefined(result.attributes.alternatives)),
    recommendedProducts: mapReferencedProducts(mapEmptyArrayToUndefined(result.attributes.recommendations)),
    targets: mapEmptyArrayToUndefined(result.attributes.targets?.target),
    references: mapEmptyArrayToUndefined(result.attributes.references),
    conjugations: mapReferencedProducts(mapEmptyArrayToUndefined(result.attributes.conjugations)),
  }
}

function mapProductSkuData(result: any) {
  const mappedSkuData: Record<string, MappedSKU> = {}
  if (result.masterVariant) {
    Object.assign(mappedSkuData, mapSkuData(result.masterVariant))
  }
  result.variants
    ?.filter((variant: any) => !!variant)
    .map((variant: any) => Object.assign(mappedSkuData, mapSkuData(variant)))
  return mappedSkuData
}

function getAttribute(attributes: any, attributeName: string) {
  return attributes.find((attribute: any) => attribute.name === attributeName)?.value
}

function mapSkuData(variant: any): Record<string, MappedSKU> {
  const { isCN, isB2DCountry } = useCountry()
  const { selectedCountry } = useUserStore()

  return {
    [variant.sku]: {
      sku: variant.sku,
      prices: variant.prices?.map((price: any) => {
        return {
          country: price.country,
          centAmount: price.value?.centAmount,
          currencyCode: price.value?.currencyCode,
          fractionDigits: price.value?.fractionDigits,
        }
      }),
      priceForCurrentCountry: variant.prices?.find(
        // FIXME: remove isCN after getClp is not used anymore (WEBP-9573)
        (price: any) => price.country === (isB2DCountry.value || isCN.value ? 'US' : selectedCountry)
      )?.value,
      attributes: mapSkuAttributes(variant.attributes),
    },
  }
}

function mapSkuAttributes(attributes: any): MappedSKUAttributes {
  return {
    active: getAttribute(attributes, 'active'),
    amount: getAttribute(attributes, 'amount'),
    assemblyOptionCode: getAttribute(attributes, 'assemblyOptionCode'),
    assemblyOptionText: getAttribute(attributes, 'assemblyOptionText'),
    code: getAttribute(attributes, 'code'),
    published: getAttribute(attributes, 'published'),
    unit: getAttribute(attributes, 'unit'),
    uom: getAttribute(attributes, 'uom'),
    tests: getAttribute(attributes, 'tests'),
  }
}

function mapProductAttributes(attributes: any, productName: string): MappedProductAttributes {
  const lots = mapProductLot(attributes.lots)
  return {
    activity: attributes.generic?.activity,
    auditLog: attributes['audit-log'],
    casNumber: attributes['cas-number'],
    certificateOfAnalysis: attributes['certificate-of-analysis'],
    companionProducts: attributes['companion-products'],
    concentration: attributes.generic?.concentration,
    conjugates: mapConjugates(attributes),
    conjugation: attributes.conjugation,
    datasheetRevNo: attributes['datasheet-rev-no'],
    dilutionBuffer: attributes['dilution-buffer'],
    directionsForUse: attributes.generic?.['directions-for-use'],
    endotoxin: attributes.generic?.endotoxin,
    epitopeTag: attributes.simple?.['epitope-tag'],
    figures: attributes.figures,
    fluorescents: attributes.fluorescents,
    formulation: attributes.generic?.formulation,
    groupName: attributes.group?.['group-name'],
    homology: attributes.homology,
    host: attributes.simple?.host,
    images: mapImages(attributes, lots, productName),
    isCNDangerousGoods: attributes.isCNDangerousGoods,
    isotype: attributes.isotype,
    isotypeControl: attributes.simple?.['isotype-control'],
    jsonUrls: attributes['json-urls'],
    keywords: attributes.keywords,
    kits: attributes['kit-components'],
    legacyPdpId: attributes['legacy-pdp-id'],
    lots,
    molecularFormula: attributes.generic?.['molecular-formula'],
    molecularWeight: attributes['molecular-weight'],
    observedMolecularWeight: attributes['observed-molecular-weight'],
    orderingDetails: attributes['ordering-details'],
    pdf: attributes.pdf,
    productBackground: attributes['product-background'],
    productStorage: attributes.package?.['product-storage'],
    productStorageTemperature: attributes.package?.['product-storage-temp'],
    protocols: mapProtocols(mapEmptyArrayToUndefined(attributes.protocols)),
    purity: attributes.generic?.purity,
    qualityControl: attributes.generic?.['quality-control'],
    reagentsNotIncluded: attributes['reagents-not-included'],
    safetyDatasheets: attributes['safety-data-sheets'],
    sciquestCategory: attributes['sciquest-category'],
    sefUrl: attributes.sefUrl,
    solubility: attributes.solubility,
    solutionsReagents: attributes.generic?.['solutions-reagents'],
    sortName: attributes.group?.['sort-name'],
    sourcePurification: attributes['source-purification'],
    specificitySensitivity: attributes['specificity-sensitivity'],
    statements: attributes.statements,
    statusDates: attributes['status-dates'],
    targetEntrezList: mapEmptyArrayToUndefined(attributes['target-entrez-list']),
    targetUniprotList: mapEmptyArrayToUndefined(attributes['target-uniprot-list']),
    unspsc: attributes.unspsc,
    xp: attributes.simple?.xp,
  }
}

function mapReferencedProducts(products: []): ReferencedProduct[] | undefined {
  if (products) {
    return products.map((product: any) => {
      return {
        id: product.key,
        name: product.name['en-US'],
        category: product.attributes.categories['web-category-name'],
        type: product.attributes.categories['product-type'],
        conjugationType: product.attributes.conjugation?.type,
        productUrl: product.attributes.sefUrl,
        productType: product.attributes.categories['product-type'],
        skuData: mapProductSkuData(product),
        attributes: mapProductAttributes(product.attributes, product.name?.['en-US']),
      }
    })
  }
}

function mapProductLot(lots: Lot | undefined) {
  if (lots?.lot.applications) {
    lots.lot.applications.application = lots.lot.applications.application.map((a) => {
      return {
        ...a,
        codeForDisplay: mapApplicationForDisplayFromGroup(a, 'codeForDisplay'),
        fullNameForDisplay: mapApplicationForDisplayFromGroup(a, 'fullNameForDisplay'),
      }
    })
  }
  return lots
}

function mapApplicationForDisplayFromGroup(application: Application, mapTo: 'codeForDisplay' | 'fullNameForDisplay') {
  switch (application.group) {
    case APPLICATIONS.WESTERN_BLOTTING.group: {
      return APPLICATIONS.WESTERN_BLOTTING[mapTo]
    }
    case APPLICATIONS.IMMUNOPRECIPITATION.group: {
      return APPLICATIONS.IMMUNOPRECIPITATION[mapTo]
    }
    case APPLICATIONS.IMMUNOHISTOCHEMISTRY.group: {
      return APPLICATIONS.IMMUNOHISTOCHEMISTRY[mapTo]
    }
    case APPLICATIONS.IMMUNOFLUORESCENCE.group: {
      return APPLICATIONS.IMMUNOFLUORESCENCE[mapTo]
    }
    case APPLICATIONS.FLOW_CYTOMETRY.group: {
      return APPLICATIONS.FLOW_CYTOMETRY[mapTo]
    }
    case APPLICATIONS.CHROMATIN_IMMUNOPRECIPITATION.group: {
      return APPLICATIONS.CHROMATIN_IMMUNOPRECIPITATION[mapTo]
    }
    case APPLICATIONS.DOT_BLOT.group: {
      return APPLICATIONS.DOT_BLOT[mapTo]
    }
    case APPLICATIONS.CLIP.group: {
      return APPLICATIONS.CLIP[mapTo]
    }
    case APPLICATIONS.CUT_AND_RUN.group: {
      return APPLICATIONS.CUT_AND_RUN[mapTo]
    }
    case APPLICATIONS.CUT_AND_TAG.group: {
      return APPLICATIONS.CUT_AND_TAG[mapTo]
    }
    default: {
      return application.group
    }
  }
}

function mapImages(attributes: any, lots: Lot | undefined, productName: string) {
  const images: Figure[] = []
  let otherImagesCounter = 0
  const applications = lots?.lot.applications?.application
  const productImages = attributes.figures?.figure

  applications?.forEach((a) => {
    a.figures?.figure.forEach((figure, index) => {
      if (applicationsWithOwnChiclet.includes(a.codeForDisplay!)) {
        return images.push({
          ...figure,
          codeForDisplay: a.codeForDisplay,
          code: a.code,
          protocolId: a.protocol?.number,
          fullNameForDisplay: a.fullNameForDisplay,
          counter: index + 1,
          productName,
        })
      }

      otherImagesCounter++
      return images.push({
        ...figure,
        codeForDisplay: 'other',
        code: a.code,
        protocolId: a.protocol?.number,
        fullNameForDisplay: a.fullNameForDisplay!,
        counter: otherImagesCounter,
        productName,
      })
    })
  })
  productImages?.forEach((figure: Figure) => {
    otherImagesCounter++
    return images.push({ ...figure, codeForDisplay: 'other', counter: otherImagesCounter, productName })
  })
  return images
}

function mapProtocols(protocols: StepProtocol[]) {
  if (!protocols) {
    return undefined
  } else {
    const mappedProtocols: Record<string, StepProtocol> = {}
    protocols.map((protocol) => Object.assign(mappedProtocols, { [protocol.number]: { ...protocol } }))
    return mappedProtocols
  }
}

function mapConjugates(attributes: any) {
  return {
    parent: attributes.conjugationsParent,
    children: attributes.conjugationsChildren,
    siblings: attributes.conjugationsSiblings,
    carrierFree: attributes.conjugationsCarrierFree,
    isParent: attributes.isConjugationParent,
    isCarrierFree: attributes.isConjugationCarrierFree,
    isChild: attributes.isConjugationChild,
  }
}

// Required for backwards compatibility due to changes made in WEBP-8803
function mapEmptyArrayToUndefined(obj: any) {
  return Array.isArray(obj) && !obj.length ? undefined : obj
}
